import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import './style.scss'
import { useState } from 'react'

const NavigationBar = () => {
  const [expanded, setIsExpanded] = useState(false)
  return (
    <Navbar
      expand='lg'
      className='text-dark navbar'
      style={{
        backgroundColor: '#FFFFFF',
        height: 'fit-content',
        padding: '0 1em',
        position: 'sticky',
      }}
      fixed='top'
      expanded={expanded}
    >
      <Container className='mw-100'>
        <Navbar.Brand className='p-2'>
          <Link to='/'>
            <StaticImage
              src='../../images/gdp_logo.png'
              alt='GDP logo'
              placeholder='blurred'
              layout='fixed'
              width={150}
              height={70}
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => setIsExpanded(!expanded)}
        />

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='nav-container p-2'>
            <Link
              className='nav-item'
              activeClassName='active-nav-item'
              to='/'
              onClick={() => setIsExpanded(false)}
            >
              ⬤ Home
            </Link>

            <Link
              className='nav-item'
              activeClassName='active-nav-item'
              to='/about'
              onClick={() => setIsExpanded(false)}
            >
              ⬤ About Us
            </Link>

            <Link
              className='nav-item'
              activeClassName='active-nav-item'
              to='/solutions'
              onClick={() => setIsExpanded(false)}
            >
              ⬤ Our Solutions
            </Link>

            <Link
              className='nav-item'
              activeClassName='active-nav-item'
              to='/blog'
              onClick={() => setIsExpanded(false)}
            >
              ⬤ Blog
            </Link>

            <Link
              className='nav-item'
              activeClassName='active-nav-item'
              to='/contact'
              onClick={() => setIsExpanded(false)}
            >
              ⬤ Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavigationBar
