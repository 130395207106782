import { Link } from 'gatsby-link'
import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import './style.scss'
import LinkedInSvg from '../../images/icons/linkedin.svg'
import FacebookSvg from '../../images/icons/facebook-square.svg'
import InstagramSvg from '../../images/icons/instagram.svg'
import { graphql, useStaticQuery } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulCycleraDocument {
        document {
          file {
            fileName
            url
          }
        }
        linkName
        isLive
      }
    }
  `).contentfulCycleraDocument
  return (
    data && (
      <Navbar expand='lg' className='text-dark p-5 footer'>
        <Container>
          <div className='footer-icons'>
            <a
              href='https://www.linkedin.com/company/green-doctors-programme/'
              className='social'
              target='_blank'
              rel='noreferrer'
            >
              <img src={LinkedInSvg} alt='linkedin icon' />
            </a>
            <a
              href='https://www.facebook.com/greendoctors.sg'
              className='social'
              target='_blank'
              rel='noreferrer'
            >
              <img src={FacebookSvg} alt='facebook icon' />
            </a>
            <a
              href='https://www.instagram.com/greendoctors.sg/'
              className='social'
              target='_blank'
              rel='noreferrer'
            >
              <img src={InstagramSvg} alt='instagram icon' />
            </a>
          </div>
        </Container>
        <Container>
          <Nav className='footer-container d-flex flex-column'>
            <p className='footer-label'>Company</p>
            <Link
              className='footer-link'
              to='/about#our-story'
              title='Our Story'
            >
              Our Story
            </Link>
            <Link className='footer-link' to='/#our-role' title='Our Role'>
              Our Role
            </Link>

            <Link
              className='footer-link'
              to='/about#team'
              title='Our Team'
              // stripHash
            >
              Our Team
            </Link>

            <Link
              className='footer-link'
              to='/#our-achievements'
              title='Our Achievements'
            >
              Our Achievements
            </Link>
          </Nav>
        </Container>
        <Container>
          <Nav className='footer-container d-flex flex-column'>
            <p className='footer-label'>Partner with us</p>
            <Link
              className='footer-link'
              to='/#our-partners'
              title='Our Partners'
            >
              Our Partners
            </Link>
            <Link className='footer-link' to='/solutions' title='Our Solutions'>
              Our Solutions
            </Link>

            <Link className='footer-link' to='/blog' title='Our Blog'>
              Blog
            </Link>

            <Link className='footer-link' to='/contact' title='Contact us'>
              Contact us
            </Link>
          </Nav>
        </Container>
        <Container className='align-self-end'>
          {data.isLive ? (
            <a
              href={data.document.file.url}
              target='_blank'
              rel='noreferrer'
              alt={data.document.file.fileName}
              className='footer-link'
            >
              {data.linkName}
            </a>
          ) : (
            <span>{data.linkName}</span>
          )}
        </Container>
      </Navbar>
    )
  )
}

export default Footer
