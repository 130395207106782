import 'bootstrap/dist/css/bootstrap.min.css'
import './src/css/style.scss'
import React from 'react'
import Footer from './src/components/Footer/Footer'
import NavigationBar from './src/components/NavigationBar/NavigationBar'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <NavigationBar />
      <div>{element}</div>
      <Footer />
    </>
  )
}
